<template>
  <div class="update-pass">
    <div class="login-form">
      <div class="login-title">
        {{title}}
      </div>
      <div class="login-body">
        <div class="body-form">
          <div class="form-title">修改密码</div>
          <el-form class="form-input" autoComplete="on" :model="modify" status-icon :rules="rules" ref="modifyPassForm" label-position="top" :hide-required-asterisk="true" >
            <el-form-item prop="oldPass" class="input-content" label="原密码">
            <el-input  placeholder="请输入原密码" v-model="modify.oldPass" name="oldPass" type="password"> 
            	</el-input>
            </el-form-item>
            <el-form-item prop="pass" class="input-content pwd-input" label="新密码">
            <el-input  placeholder="请输入密码" type="password" v-model="modify.pass" name="pass"></el-input>
            </el-form-item>
            <el-form-item prop="checkPass" class="input-content pwd-input" label="确认密码">
            <el-input  placeholder="请输入密码" type="password" v-model="modify.checkPass" name="checkPass"></el-input>
            </el-form-item>
            <div class="other-input">
            </div>
            <el-button type="primary" class="login-btn" :loading="loading" @click.native.prevent="submitModifyPass">修改密码</el-button>
          </el-form>
          <div class="footer">Copyright &copy;</div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
  import {login,updateAdminPassword,signout} from '@/api/getUcenterData'
  import CryptoJS from 'crypto-js/crypto-js'
  import cookies from '@/utils/cookies/index'
  import setting from '@/utils/config/index.js'
  export default {
    data () {
      return {
        modify:{
          oldPass:"",
          pass:"",
          checkPass:"",
        },
        title:setting.info.LoginText,
        rules: {
          oldPass: [
            { required: true, message: '请输入原先密码', trigger: 'blur' }
          ],
          pass: [
            { validator: this.validatePass, trigger: 'blur' },
            { min: 8,max: 25,message: '长度在 8 到 25个字符'}, 
            {pattern: /(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,25}/, message: '只能输入8-25个,且必须有数字字母特殊字符组合'}
          ],
          checkPass: [
            { validator: this.validatePass2, trigger: 'blur' }
          ]
        },
        userName:'',
        loading:false,
    }
  },
  mounted () {
  },
  created () {
    let uid = cookies.get("uid");
    this.user = JSON.parse(localStorage.getItem("userInfo" + uid));
  },
  beforeDestroy () {
    document.onkeydown = undefined
  },
  methods:{
    validatePass:function(rule, value, callback){
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else {
        if (this.modify.checkPass !== '' ) {
          this.$refs.modifyPassForm.validateField('checkPass');
        }
        callback();
      }
    },
    validatePass2:function(rule, value, callback){
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.modify.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    },
    submitModifyPass:function(){
        //提交修改密码
        this.$refs["modifyPassForm"].validate((valid) => {
          if(valid){
            let param = {
              user_name:this.user.user_name,
              password:this.modify.pass,
              oldPass:this.modify.oldPass
            }
            updateAdminPassword(param).then((res)=>{
              if(res.resultCode == 1){
                this.$message({message: '修改成功，请重新登陆',type: 'success'});
                this.signout(false);
              }else{
                this.$message({message: res.resultMsg ,type: 'error'});
              }
            })
          }
        })
    },
    signout:function(showMsg = true){
      //退出
      signout().then((res)=>{
        if(showMsg){            
          this.$message({type: 'success',message: '请重新登陆'});
        }
        cookies.remove('uid');
        cookies.remove('token')
        window.location.href = window.location.protocol + '//' + window.location.host + '/base/login';
      })
    }
  }
}
</script>

<style lang="scss">
.update-pass {
  position:relative;
  height:100vh;
  // background:url('~@/modules/ucenter/assets/image/bgt.jpg') no-repeat;
  background-size: 100% 100%;

  input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px #FFF inset !important;
      -webkit-text-fill-color: #525252 !important;
  }
  input {
    background: transparent;
    border: none;
    border-bottom:1px solid  #C9D2DB;
    -webkit-appearance: none;
    border-radius: 0px;
    padding:0px;
    color: #525252;
    height: 47px;
  }

  .el-form--label-top .el-form-item__label{
    padding:0px;
    line-height: 1.5rem;
    font-size: 0.6rem;
  }
  
  
  .login-form{
    width:32.5rem;
    height:32.25rem;
    position:absolute;
    left:50%;
    top: 5.9rem;
    margin-left: -16.25rem;
  }

  .login-title{
    font-family: "Comic Sans MS", cursive, sans-serif;
    text-align: center;
    font-size: 36px;
    color:#fff;    
  }

  .login-body{
    margin: 5% 0;
    width:100%;
    // background-color: #19BF7D;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.3);
    display:flex;
    .body-form{      
      border-radius: 10px;
      width:100%;
      height:490px;
      background-color:#FFFFFF;
    }

    .form-title{
      height:4rem;
      line-height:4rem;
      padding-left: 2.65rem;
      color:#21293B;
      font-size:1rem;
      font-weight:500;
    }

    .form-input{
      padding:0 2.65rem;


      .other-input{
        height:0.5rem;
        line-height:0.5rem;
        display: -webkit-flex;
        display: fiex;
        justify-content: space-between;
        .remember-me{
          display:inline-block;
          height:30px; 
          line-height:30px;         
          .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
            background-color:#67C23A;
            border-color:#67C23A;
          }
          .el-checkbox__label{
            color: #525252;
          }
        }
      }
    }

    .login-btn{
      width:5.1rem;  
      // height:  1.75rem;
      // border-radius:0.875rem; 
      // font-size: 0.8rem;
      // padding:0;
    }

    .forget-pwd{
      cursor: pointer;
    }

    .footer{
      text-align: center;
      font-size: 12px;
      margin-top: 1rem;
      color: #C0C4CC;
    }
  }
}
</style>
